/*jslint vars: true, plusplus: true, devel: true, nomen: true, indent: 4, maxerr: 50 */
(function(){
	'use strict';

$( document ).ready(function() {

	// --------------------------------------------------------------------
	// OBJECT FIT IMAGES
	objectFitImages();

	// --------------------------------------------------------------------
	// SET COOKIE
	function getCookie(cname) {
	    var name = cname + "=";
	    var ca = document.cookie.split(';');

	    for (var i=0; i < ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1);
	        if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
	    }
	    return false;
	}
	function setCookie(cname, cvalue) {
		var d = new Date();
	    d.setTime(d.getTime() + (9000*24*60*60*1000));
	    var expires = "expires="+d.toUTCString();
	    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/;secure;";  //secure werkt enkel over https 
	}

	// --------------------------------------------------------------------
	// COOKIE MESSAGE
	$('#cookie-close').click(function() {
		// $('#cookie-banner').hide('fast');
		$('#cookie-banner').removeClass('visible');
		setCookie('visited', 'true');
	});

	// --------------------------------------------------------------------
	// HAMBURGER / NAV
	$('.hamburger').click(function() {
		$('body').toggleClass('stop-scrolling');
		$(this).toggleClass('is-active');
		$('.menu-menu-1-container').toggleClass('show-nav');
	});
	$(window).on('resize',function() {
		var windowsize = $(window).width();
		if(windowsize >= 960) {
			$('body').removeClass('stop-scrolling');
			$('.hamburger').removeClass('is-active');
			$('.menu-container').removeClass('show-nav');
		}
	});

	var sliderChildrenCount = $('.inner-cases-slider').children().length + 1;
	var sliderWidth = 230 * sliderChildrenCount;
	$('.inner-cases-slider').width(sliderWidth + 'px');

});

})(); // END OF USE STRICT FUNCTION